import React from 'react';
import Accordion from "../../commons/Accordion";
import Button from "../../commons/button";
import {makeStaticParallax} from "../../../helpers/makeParalax";

const Faqs = ({t}) => {

    const faqs = t('common:faqs', {}, {returnObjects: true});

    const clouds = [
        {
            name: 'cloud_left',
            className: 'cloud cloud_left_1',
            width: '156',
            height: '70'
        }, {
            name: 'cloud_left',
            className: 'cloud cloud_left_2',
            width: '216',
            height: '98'
        }, {
            name: 'cloud_left',
            className: 'cloud cloud_left_3',
            width: '156',
            height: '70'
        }, {
            name: 'cloud_left',
            className: 'cloud cloud_left_4',
            width: '239',
            height: '108'
        }, {
            name: 'cloud_left',
            className: 'cloud cloud_left_5',
            width: '176',
            height: '91'
        }, {
            name: 'cloud_left',
            className: 'cloud cloud_left_6',
            width: '223',
            height: '101'
        }, {
            name: 'cloud_left',
            className: 'cloud cloud_left_7',
            width: '264',
            height: '119'
        }
    ]

    return (
        <div className='faqs_section' id='faqs'>
            <div className="container">
                <div className="faqs_section_wrapper">
                    {makeStaticParallax(clouds, '/img/pages/home/progress/', [3, 8])}
                    <div style={{position: 'relative'}} data-aos="fade-up" data-aos-duration="800" data-aos-easing="ease-out-quad"
                         data-aos-delay={0}
                    >
                        <span className='team_section_sub_title'>{faqs.title}</span>
                        <h4 className='team_section_title'>{faqs.sub_title}</h4>
                    </div>
                    <Accordion
                        faqs_list={faqs.data}
                    />
                </div>
                {/*<div className="buy_button">*/}
                {/*    <Button*/}
                {/*        gtm_event={'faqs_sign_up'}*/}
                {/*        className={'main_button'}*/}
                {/*        href={process.env.NEXT_PUBLIC_SIGN_UP_URL}*/}
                {/*        text={faqs.button}*/}
                {/*    />*/}
                {/*</div>*/}
            </div>
        </div>
    );
};

export default Faqs;