import React from 'react';
import Link from "next/link";
import AnimButton from "../../commons/AnimButton";

const BannerSection = ({t}) => {

    const banner = t('common:banner', {}, {returnObjects: true})

    return (
        <section className='banner_section section_scroll' data-link='link_home' id='banner'>
            {/*<div className='scheme_bg'/>*/}
            <div className='container'>
                <div className="banner_wrapper">
                    <div className='title_image'
                         data-aos-duration="1400" data-aos-easing="ease-out-quad"
                        // data-aos-delay="1000"
                    >
                        <picture>
                            <source
                                media="(max-width: 768px)"
                                srcSet={`/img/pages/home/banner/title_main_small.webp 1x, /img/pages/home/banner/title_main_small_x2.webp 2x`}
                                type="image/webp"
                            />
                            <source
                                media="(min-width: 769px)"
                                srcSet={`/img/pages/home/banner/title_main.webp 1x, /img/pages/home/banner/title_main_x2.webp 2x`}
                                type="image/webp"
                            />
                            <source
                                media="(max-width: 768px)"
                                srcSet={`/img/pages/home/banner/title_main_small.png 1x, /img/pages/home/banner/title_main_small_x2.png 2x`}
                            />
                            <source
                                media="(min-width: 769px)"
                                srcSet={`/img/pages/home/banner/title_main.png 1x, /img/pages/home/banner/title_main_x2.png 2x`}
                            />

                            <img
                                srcSet="/img/pages/home/banner/title_main.png 1x, /img/pages/home/banner/title_main_x2.png 2x"
                                src="/img/pages/home/banner/title_main.png"
                                alt="Buy alex the doge"
                                width={660}
                                height={181}
                                className='title_image_title'
                            />
                        </picture>
                        <picture>
                            <source
                                srcSet={`/img/pages/home/banner/title_sub.webp 1x, /img/pages/home/banner/title_sub_x2.webp 2x`}
                                type="image/webp"
                            />
                            <img
                                srcSet="/img/pages/home/banner/title_sub.png 1x, /img/pages/home/banner/title_sub_x2.png 2x"
                                src="/img/pages/home/banner/title_sub.png"
                                alt="Buy alex the doge"
                                width={590}
                                height={82}
                                className='title_image_title_sub'
                            />
                        </picture>
                        <picture>
                            <source
                                srcSet={`/img/pages/home/banner/blur.webp`}
                                type="image/webp"
                            />
                            <img
                                src="/img/pages/home/banner/blur.png"
                                alt="Buy alex the doge"
                                width={991}
                                height={809}
                                className='title_image_ellipse'
                            />
                        </picture>
                    </div>
                    <Link

                        prefetch={false}
                        href={process.env.NEXT_PUBLIC_BUY_TOKEN_APP_URL}>
                        {/*href={'https://app.alexthedoge.live/sign-up'}>*/}
                        <a
                            // data-aos="fade-up" data-aos-duration="1000" data-aos-easing="ease-out-quad"
                            // data-aos-delay="1400"
                            className='buy_button'
                            target={'_self'}
                        >
                            <AnimButton
                                text={banner.btn}
                                ownStyles={{
                                    textTransform: 'uppercase'
                                }}
                            />
                        </a>
                    </Link>
                    <div className='image_main'>
                        <picture>
                            <source
                                srcSet={`/img/pages/home/banner/alex_man.webp 1x, /img/pages/home/banner/alex_man_x2.webp 2x`}
                                type="image/webp"
                            />
                            <img
                                srcSet="/img/pages/home/banner/alex_man.png 1x, /img/pages/home/banner/alex_man_x2.png 2x"
                                src="alex_man.png"
                                alt="Buy alex the doge"
                                width={307}
                                height={402}
                                className='image_main_alex'
                            />
                        </picture>
                        <picture>
                            <source
                                type="image/webp"
                                media="(max-width: 768px)"
                                srcSet={`/img/pages/home/banner/cubs_small.webp 1x, /img/pages/home/banner/cubs_small_x2.webp 2x`}
                            />
                            <source
                                type="image/webp"
                                media="(min-width: 769px)"
                                srcSet={`/img/pages/home/banner/cubs.webp 1x, /img/pages/home/banner/cubs_x2.webp 2x`}
                            />
                            <source
                                media="(max-width: 768px)"
                                srcSet={`/img/pages/home/banner/cubs_small.png 1x, /img/pages/home/banner/cubs_small_x2.png 2x`}
                            />
                            <source
                                media="(min-width: 769px)"
                                srcSet={`/img/pages/home/banner/cubs.png 1x, /img/pages/home/banner/cubs_x2.png 2x`}
                            />
                            <img srcSet="/img/pages/home/banner/cubs.png 1x, /img/pages/home/banner/cubs_x2.png 2x"
                                 src="/img/pages/home/banner/cubs.png"
                                 alt="Buy alex the doge"
                                 width={1059}
                                 height={500}
                                 className='image_main_cubs useful_img'
                            />
                        </picture>
                        <picture>
                            <source
                                type="image/webp"
                                media="(max-width: 768px)"
                                srcSet={`/img/pages/home/banner/coins_small.webp 1x, /img/pages/home/banner/coins_small_x2.webp 2x`}
                            />
                            <source
                                type="image/webp"
                                media="(min-width: 769px)"
                                srcSet={`/img/pages/home/banner/coins.webp 1x, /img/pages/home/banner/coins_x2.webp 2x`}
                            />
                            <source
                                media="(max-width: 768px)"
                                srcSet={`/img/pages/home/banner/coins_small.png 1x, /img/pages/home/banner/coins_small_x2.png 2x`}
                            />
                            <source
                                media="(min-width: 769px)"
                                srcSet={`/img/pages/home/banner/coins.png 1x, /img/pages/home/banner/coins_x2.png 2x`}
                            />
                            <img srcSet="/img/pages/home/banner/coins.png 1x, /img/pages/home/banner/coins_x2.png 2x"
                                 src="coins.png"
                                 alt="Buy alex the doge"
                                 width={284}
                                 height={436}
                                 className='image_main_coins'
                            />
                        </picture>
                        <picture>
                            <source
                                type="image/webp"
                                srcSet={`/img/pages/home/banner/sun.webp 1x, /img/pages/home/banner/sun_x2.webp 2x`}
                            />
                            <img srcSet="/img/pages/home/banner/sun.png 1x, /img/pages/home/banner/sun_x2.png 2x"
                                 src="/img/pages/home/banner/sun.png"
                                 alt="Buy alex the doge"
                                 width={174}
                                 height={174}
                                 className='image_main_sun useful_img'
                            />
                        </picture>
                        <picture>
                            <source
                                type="image/webp"
                                srcSet={`/img/pages/home/banner/sunrays.webp 1x, /img/pages/home/banner/sunrays_x2.webp 2x`}
                            />
                            <img
                                srcSet="/img/pages/home/banner/sunrays.png 1x, /img/pages/home/banner/sunrays_x2.png 2x"
                                src="/img/pages/home/banner/sunrays.png"
                                alt="Buy alex the doge"
                                width={275}
                                height={64}
                                className='image_main_sunrays useful_img'
                            />
                        </picture>
                        <picture>
                            <source
                                type="image/webp"
                                media="(max-width: 768px)"
                                srcSet={`/img/pages/home/banner/mountains_small.webp 1x, /img/pages/home/banner/mountains_small_x2.webp 2x`}
                            />
                            <source
                                type="image/webp"
                                media="(min-width: 769px)"
                                srcSet={`/img/pages/home/banner/mountains.webp 1x, /img/pages/home/banner/mountains_x2.webp 2x`}
                            />
                            <source
                                media="(max-width: 768px)"
                                srcSet={`/img/pages/home/banner/mountains_small.png 1x, /img/pages/home/banner/mountains_small_x2.png 2x`}
                            />
                            <source
                                media="(min-width: 769px)"
                                srcSet={`/img/pages/home/banner/mountains.png 1x, /img/pages/home/banner/mountains_x2.png 2x`}
                            />
                            <img
                                srcSet="/img/pages/home/banner/mountains.png 1x, /img/pages/home/banner/mountains_x2.png 2x"
                                src="/img/pages/home/banner/mountains.png"
                                alt="Buy alex the doge"
                                width={1040}
                                height={234}
                                className='image_main_mountains useful_img'
                            />
                        </picture>
                    </div>


                </div>
            </div>
        </section>
    );
};

export default BannerSection;