import React, {useEffect, useState} from 'react';
import TitleWithImages from "../../commons/TitleWithImages";
import AnimButton from "../../commons/AnimButton";
import Link from "next/link";
import {makeStaticParallax} from "../../../helpers/makeParalax";

const HowTo = ({t, isMobile}) => {

    const how_to = t('common:how_to', {}, {returnObjects: true})

    const Item = ({item, i}) => {
        return (
            <li
                data-aos="fade-up" data-aos-duration="800" data-aos-easing="ease-out-quad"
                data-aos-delay={(i + 1) * 200}
                className='how_to_section_items_item'>


                <div className='icon'>
                    <img width={item.icon_w} height={item.icon_h} alt='icon'
                                           src={`/img/pages/home/how_to/${item.icon}.svg`}
                    />
                    {makeStaticParallax(item.clouds, '/img/pages/home/progress/', [1, 3])}
                </div>

                <div className='content'>
                    <h5 className='content_title'>{item.title}</h5>
                    <p className='content_text'>{item.text}</p>
                </div>
            </li>
        )
    }

    return (
        <section className='how_to_section' id='how_to'>
            <div className="container">

                <div
                    data-aos="fade-up" data-aos-duration="800" data-aos-easing="ease-out-quad"
                    data-aos-delay={0}
                >
                    <span className='how_to_section_sub_title'>{how_to.title}</span>
                </div>
                <div className='how_to_section_title'
                     data-aos="fade-up" data-aos-duration="800" data-aos-easing="ease-out-quad"
                     data-aos-delay={0}
                >
                    <h4 className='how_to_section_title_title'>{how_to.main_title}</h4>
                    <Link prefetch={false}
                          href={process.env.NEXT_PUBLIC_BUY_TOKEN_APP_URL}>
                          {/*href={'https://app.alexthedoge.live/sign-up'}>*/}
                        <a
                            className='buy_button'
                            target={'_self'}
                        >
                            <AnimButton
                                white={true}
                                text={how_to.join_button}
                                ownStyles={{
                                    padding: '18px 54px',
                                    fontSize: '24px',
                                    lineHeight: '1'
                                }}
                            />
                        </a>
                    </Link>
                </div>
                <ul className='how_to_section_items'>
                    {how_to.items.map((item, i) => {
                        return <Item
                            i={i}
                            key={item.index}
                            item={item}
                        />
                    })}
                </ul>
            </div>
        </section>
    );
};

export default HowTo;