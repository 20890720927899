import React from 'react'
import AnimButton from "../../../commons/AnimButton";
import Link from "next/link";

const Counter = ({data, stageData}) => {

    return (
        <div className='countdown_container'>
            <h3 className='countdown_title' style={{textAlign: 'center'}}><span>{data.title_stage_1}</span>
                <span>{data.title_stage_2} {stageData?.stage_num}</span>
            </h3>
            <Link
                prefetch={false}
                href={process.env.NEXT_PUBLIC_BUY_TOKEN_APP_URL}>
                <a
                    className='buy_button'
                    target={'_self'}
                >
                    <AnimButton
                        text={data.btn}
                        ownStyles={{
                            textTransform: 'uppercase'
                        }}
                    />
                </a>
            </Link>
        </div>
    )
}

export default Counter