import React, {useEffect, useState} from 'react';

const ProgressLine = ({pct}) => {

    return (
        <div className='progress_wrapper'>
            <div className='progress'>
                <div style={{width: `${pct}%`}} className="progress_bar progress-bar-striped"/>
            </div>
        </div>
    );
};

export default ProgressLine;