import React from 'react';
import Counter from "./counter";

const CounterGroup = ({content, stageData}) => {
    return (
        <div className='counter_group'>
            <div className='price_data'>
                <picture>
                    <source srcSet="/img/main/big_logo.webp" type="image/webp"/>
                    <img width='100' height='100' className='price_data_image' alt='alex doge logo'
                         src='/img/main/big_logo.png'/>
                </picture>
                <div className='price_data_data'>
                    <span className='price_data_data_token'>ALEX THE DOGE</span>
                    <span
                        className='price_data_data_price'>CURRENT PRICE = {Number(stageData?.token_price)?.toFixed(4) || '0.00'} USD</span>
                </div>
            </div>
            <div className='logo'>
                <picture>
                    <source
                        srcSet={`/img/pages/home/progress/progress_doge.webp 1x, /img/pages/home/progress/progress_doge_x2.webp 2x`}
                        type="image/webp"
                    />
                    <img
                        srcSet="/img/pages/home/progress/progress_doge.png 1x, /img/pages/home/progress/progress_doge_x2.png 2x"
                        width='100' height='100' className='logo_image' alt='alex doge logo'
                        src='/img/pages/home/progress/progress_doge.png'
                    />
                </picture>
            </div>
            <div className='counter_data'>
                <Counter
                    stageData={stageData}
                    data={content}
                />
            </div>
        </div>
    );
};

export default CounterGroup;