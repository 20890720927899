import React, {useState} from 'react';

const AccordionItem = ({item, index, activeIndex, setActiveIndex}) => {

    return <li
        // data-aos-offset="0" data-aos="fade-up" data-aos-duration="800" data-aos-easing="ease-out-quad" data-aos-delay="0"
    >
        <div
            onClick={(e) => {
                if (!activeIndex || activeIndex !== index) {
                    setActiveIndex(index)
                } else {
                    setActiveIndex(null)
                }
            }}
            className={`accordion_item_wrapper ${activeIndex === index ? 'is_active' : ''}`}
        >
            <div className='accordion_item'>
                <h4 className='accordion_item_title'>{item.title}</h4>
                <span className='accordion_item_icon'/>
            </div>

            {item.email ?<div className={`accordion_item_body`}>
                {item.body_1} <a style={{color: '#F8BB31'}}
                                 href={`mailto:${item.email}`}>{item.email}</a> {item.body_2}
            </div> : <div className={`accordion_item_body`}>
                {item.body}
            </div>}
        </div>
    </li>
}

const Accordion = ({faqs_list}) => {

    const [activeIndex, setActiveIndex] = useState(null)

    return (
        <div className='accordion_container'>
            <ul className='accordion_data'>
                {faqs_list.map((item) => {
                    return <AccordionItem
                        activeIndex={activeIndex}
                        setActiveIndex={setActiveIndex}
                        index={item.index}
                        key={item.index}
                        item={item}
                    />
                })}
            </ul>
        </div>
    );
};

export default Accordion;