import React, {useEffect, useState} from "react";
import ProgressLine from "../../../commons/Progress";

const Progress = (props) => {

    const {data} = props

    const [complete, setComplete] = useState(0);
    const [completed, setCompleted] = useState(0);

    useEffect(() => {
        setTimeout(() => {
            setComplete(completed)
        }, 1000)
    }, [completed])

    useEffect(() => {
        if (Number(data?.tokens_sold) && Number(data?.tokens_sold) !== 0) {
            setCompleted((Number(data?.tokens_sold) * 100) / Number(data?.target_raise))
        } else {
            setCompleted(0)
        }
    }, [data])

    return (
        <ProgressLine
            pct={complete}
        />
    );
};

export default Progress;