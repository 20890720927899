import React from 'react';

const Tokenomics = ({t, isMobile}) => {

    const tokenomics = t('common:tokenomics', {}, {returnObjects: true})

    const ItemMain = ({item, i}) => {
        return <div className='tokenomics_section_main_item'
                    data-aos="fade-up" data-aos-duration="800" data-aos-easing="ease-out-quad"
                    data-aos-delay={(i + 1) * 100}
                    data-aos-offset='-100'
        >
            <div className='title_wrapper'>
                <h5 className='title'>{item.title}</h5>
                <p className='value'>{item.value}</p>
            </div>
            <picture>
                <source
                    srcSet={`/img/pages/home/tokenomics/box.webp 1x, /img/pages/home/tokenomics/box_x2.webp 2x`}
                    type="image/webp"
                />
                <img
                    // loading={'lazy'}
                    srcSet={`/img/pages/home/tokenomics/box.png 1x, /img/pages/home/tokenomics/box_x2.png 2x`}
                    src={`/img/pages/home/tokenomics/box.png`}
                    alt="Buy alex the doge"
                    width={item.img_width}
                    height={item.img_height}
                    className={item?.class}
                />
            </picture>
        </div>
    }

    const ItemAdd = ({item, i}) => {
        return <div
            data-aos="fade-up" data-aos-duration="800" data-aos-easing="ease-out-quad"
            data-aos-delay={(i + 1) * 100}
            className='tokenomics_section_add_item'>
            {item.uni ?
                <img className='icon' alt='icon' width={180} height={33}
                     src={`/img/pages/home/tokenomics/uniswap.svg`}/>
                : <h5 className='title'>{item.title}</h5>}
            <p className='value'>{item.value}</p>
        </div>
    }

    return (
        <section className='tokenomics_section' id='token'>
            <div className="container">
                <div className="tokenomics_section_wrapper">
                    <div
                        data-aos="fade-up" data-aos-duration="800" data-aos-easing="ease-out-quad"
                        data-aos-delay={0}
                    >
                        <span className='tokenomics_section_sub_title'>{tokenomics.title}</span>
                    </div>
                    <h4
                        data-aos="fade-up" data-aos-duration="800" data-aos-easing="ease-out-quad"
                        data-aos-delay={0}
                        className='tokenomics_section_title'
                    >{tokenomics.main_title}</h4>
                    <div
                        className='tokenomics_section_main'
                    >
                        {tokenomics.data_main.map((item, i) => {
                            return <ItemMain
                                key={item.bg}
                                item={item}
                                i={i}
                            />
                        })}
                    </div>
                    <div className='tokenomics_section_add'>
                        {tokenomics.data_add.map((item, i) => {
                            return <ItemAdd
                                i={i}
                                key={item.index}
                                item={item}
                            />
                        })}
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Tokenomics;