import React, {useEffect, useState} from 'react';
import Progress from "./progress";
import {numberWithCommasNull} from "../../../../helpers/numberWithCommas";

const ProgressGroup = ({content, stageData}) => {

    const [stage_number, setStage_number] = useState(1);

    return (
        <div className='progress_group'>
            <div className='statistic_data'>
                <span className='statistic_data_total'>{content.total_sold} {numberWithCommasNull(Number(stageData?.tokens_sold))}</span>
                <span className='statistic_data_target'> {content.target_target} {numberWithCommasNull(Number(stageData?.target_raise))} $ALEX</span>
            </div>
            <div className='progress_data'>
                <Progress
                    data={stageData}
                />
            </div>
        </div>
    );
};

export default ProgressGroup;