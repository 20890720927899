import React from 'react';
import TitleWithImages from "../../commons/TitleWithImages";
import BorderedItemHovered from "../../commons/BorderedItemHovered";
import {makeStaticParallax} from "../../../helpers/makeParalax";

const Team = ({t}) => {

    const team = t('common:team', {}, {returnObjects: true})

    const Item = ({item, i}) => {
        return <li className='team_section_crew_item'
                   data-aos="fade-up" data-aos-duration="800" data-aos-easing="ease-out-quad"
                   data-aos-delay={(i + 1) * 100}
        >
            <picture>
                <source
                    srcSet={`/img/pages/home/team/${item.icon}.webp 1x, /img/pages/home/team/${item.icon}_x2.webp 2x`}
                    type="image/webp"
                />
                <img
                    // loading={'lazy'}
                    srcSet={`/img/pages/home/team/${item.icon}.png 1x, /img/pages/home/team/${item.icon}_x2.png 2x`}
                    width='178' height='205' className='icon' alt='alex doge logo'
                    src={`/img/pages/home/team/${item.icon}.png`}
                />
            </picture>
            <h4 className='name'>{item.name}</h4>
            <p className='position'>{item.position}</p>

        </li>
    }

    const clouds = [
        {
            name: 'cloud_left',
            className: 'cloud cloud_left_1',
            width: '156',
            height: '70'
        }, {
            name: 'cloud_left',
            className: 'cloud cloud_left_2',
            width: '216',
            height: '98'
        }, {
            name: 'cloud_left',
            className: 'cloud cloud_left_3',
            width: '156',
            height: '70'
        }, {
            name: 'cloud_left',
            className: 'cloud cloud_left_4',
            width: '239',
            height: '108'
        }, {
            name: 'cloud_left',
            className: 'cloud cloud_left_5',
            width: '176',
            height: '91'
        }, {
            name: 'cloud_left',
            className: 'cloud cloud_left_6',
            width: '223',
            height: '101'
        }, {
            name: 'cloud_left',
            className: 'cloud cloud_left_7',
            width: '264',
            height: '119'
        }, {
            name: 'cloud_left',
            className: 'cloud cloud_left_8',
            width: '125',
            height: '57'
        }, {
            name: 'cloud_left',
            className: 'cloud cloud_left_9',
            width: '264',
            height: '119'
        },
    ]

    return (
        <section className='team_section'>
            <div className="container">
                <div className="team_section_wrapper">
                    {makeStaticParallax(clouds, '/img/pages/home/progress/', [3, 8])}
                    <div style={{position: 'relative'}} data-aos="fade-up" data-aos-duration="800"
                         data-aos-easing="ease-out-quad"
                         data-aos-delay={0}
                    >
                        <span className='team_section_sub_title'>{team.title}</span>
                        <h4 className='team_section_title'>{team.main_title}</h4>
                    </div>
                    <ul className='team_section_crew'
                        data-aos="fade-zoom-in"
                        data-aos-easing="ease-in-out"
                        data-aos-delay="100"
                    >
                        {team.crew.map((item, i) => {
                            return <Item
                                key={item.name}
                                item={item}
                                i={i}
                            />
                        })}
                    </ul>
                </div>
            </div>
        </section>
    );
};

export default Team;