
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    
import MainLayout from "../layouts/Main";
import useTranslation from "next-translate/useTranslation";
import Header from "../components/header";
import {useState, useEffect} from "react";
import BannerSection from "../components/home_page/BannerSection";
import ProgressSection from "../components/home_page/ProgressSection";
import HowTo from "../components/home_page/HowTo";
import Tokenomics from "../components/home_page/Tokenomics";
import Team from "../components/home_page/Team";
import RoadMap from "../components/home_page/RoadMap";
import ImagesBlock from "../components/home_page/ImagesBlock";
import Verse from "../components/home_page/Verse";
import Faqs from "../components/home_page/faqs";
import {useRouter} from "next/router";

export default function Home({title, description, canonical}) {
    const router = useRouter();

    const {t} = useTranslation('common');
    const seo = t('common:seo', {}, {returnObjects: true})
    const [isLoading, setIsLoading] = useState(true)
    const [hash, setHash] = useState('')
    const [isMobile, setIsMobile] = useState(false)
    const [isImagesLoaded, setIsImagesLoaded] = useState(false)
    const [stageData, setStageData] = useState(undefined)

    const getStatistics = async () => {
        const res = await fetch(process.env.NEXT_PUBLIC_MAIN_API_URL + '/statistic', {
            method: 'GET'
        })
        if (res) {
            return await res.json()
        } else {
            return {}
        }
    }

    useEffect(() => {

        let imgs = document.querySelectorAll('.useful_img');
        let length = imgs.length
        let count = 1
        imgs.forEach(img => {
            let im = new Image()
            im.src = img.src;

            im.onload = () => {
                count += 1
                if (count >= length) {
                    setTimeout(() => {
                        setIsImagesLoaded(true)
                    }, 100)
                    document.querySelector('.banner_section').classList.add('loaded')
                }
            }
        })

        getStatistics().then(data => {
            setStageData(data)
        }).catch(err => {
            setStageData(
                {
                    token_price: "0.0084",
                    tokens_sold: 0,
                    target_raise: 0,
                    stage_num: 1
                }
            )
        })

        window.innerWidth < 768 ? setIsMobile(true) : setIsMobile(false);

        const resizeListener = () => {
            window.innerWidth < 768 ? setIsMobile(true) : setIsMobile(false);
        }

        window.addEventListener('resize', resizeListener)
        return () => {
            window.removeEventListener('resize', resizeListener)
        }
    }, [])

    useEffect(() => {
        if (isImagesLoaded && stageData) {
            setIsLoading(false)
        }
    }, [isImagesLoaded, stageData])

    return (
        <MainLayout
            setHash={setHash}
            t={t}
            isLoading={isLoading}
            title={title ? title : seo.index_page.title}
            description={description ? description : seo.index_page.description}
            canonical={router.locale === 'en' ? `https://alexthedoge.live` : `https://alexthedoge.live/${router.locale}`}
        >
            <Header
                isMobile={isMobile}
                setHash={setHash}
                hash={hash}
                t={t}
            />
            <main className='main'>
                <BannerSection
                    isMobile={isMobile}
                    t={t}
                />

                <ProgressSection
                    stageData={stageData}
                    isMobile={isMobile}
                    t={t}
                />
                <ImagesBlock
                    isMobile={isMobile}
                    t={t}
                />

                <HowTo
                    isMobile={isMobile}
                    t={t}
                />
                <Verse
                    isMobile={isMobile}
                    t={t}
                />
                <Tokenomics
                    isMobile={isMobile}
                    t={t}
                />
                <RoadMap
                    t={t}
                />
                <Team
                    t={t}
                />
                <Faqs
                    t={t}
                />
            </main>
        </MainLayout>
    )
}



    export async function getStaticProps(ctx) {
        
        
        return {
          
          
          props: {
            
            ...(await __loadNamespaces({
              ...ctx,
              pathname: '/index',
              loaderName: 'getStaticProps',
              ...__i18nConfig,
              loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
            }))
          }
        }
    }
  