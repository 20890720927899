import React from 'react';
import ProgressGroup from "./ProgressGroup";
import CounterGroup from "./CounterGroup";
import {makeStaticParallax} from '../../../helpers/makeParalax'

const ProgressSection = (props) => {

    const {t, stageData} = props;
    const progress = t('common:progress', {}, {returnObjects: true});

    const clouds = [
        {
            name: 'cloud_left',
            className: 'cloud_left',
            width: '296',
            height: '135'
        },
        {
            name: 'cloud_right',
            className: 'cloud_right',
            width: '296',
            height: '135'
        }
    ]

    return (
        <section
            className='progress_count_section' id='about'>
            <div className="container">
                <div className='cloud'>
                    {makeStaticParallax(clouds, '/img/pages/home/progress/', [3, 8])}
                </div>
                <div className='progress_section_wrapper'>

                    <CounterGroup
                        stageData={stageData}
                        content={progress.counter_part}
                    />
                    <ProgressGroup
                        stageData={stageData}
                        content={progress.progress_part}
                    />
                </div>
            </div>

        </section>
    );
};

export default ProgressSection;