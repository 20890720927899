import React from 'react';

import {makeStaticParallax} from "../../../helpers/makeParalax";
import {Tooltip as ReactTooltip} from "react-tooltip";

const Verse = ({t}) => {

    const verse = t('common:verse', {}, {returnObjects: true})

    const clouds = [
        {
            name: 'cloud_left',
            className: 'cloud cloud_1',
            width: '207',
            height: '93'
        },
        {
            name: 'cloud_left',
            className: 'cloud cloud_2',
            width: '277',
            height: '125'
        },
        {
            name: 'cloud_left',
            className: 'cloud cloud_3',
            width: '213',
            height: '96'
        },
        {
            name: 'cloud_left',
            className: 'cloud cloud_4',
            width: '140',
            height: '63'
        },
        {
            name: 'cloud_left',
            className: 'cloud cloud_5',
            width: '174',
            height: '80'
        },
        {
            name: 'cloud_left',
            className: 'cloud cloud_6',
            width: '174',
            height: '80'
        },
        {
            name: 'cloud_left',
            className: 'cloud cloud_7',
            width: '139',
            height: '63'
        },
        {
            name: 'cloud_left',
            className: 'cloud cloud_8',
            width: '101',
            height: '46'
        },
        {
            name: 'cloud_left',
            className: 'cloud cloud_9',
            width: '50',
            height: '23'
        },
        {
            name: 'cloud_left',
            className: 'cloud cloud_10',
            width: '85',
            height: '38'
        },
        {
            name: 'cloud_left',
            className: 'cloud cloud_11',
            width: '102',
            height: '46'
        },
        {
            name: 'cloud_left',
            className: 'cloud cloud_12',
            width: '102',
            height: '46'
        }
    ]

    const stars = [
        {
            name: 'star',
            className: 'star star_1',
            width: '44',
            height: '59'
        }, {
            name: 'star',
            className: 'star star_2',
            width: '44',
            height: '59'
        }, {
            name: 'star',
            className: 'star star_3',
            width: '65',
            height: '87'
        }
    ]

    return (
        <section className='verse_section' id='verse'>
            <div className="container">
                <div className="verse_section_wrapper">
                    {clouds.map(el => {
                        return <picture>
                            <source
                                srcSet={`/img/pages/home/progress/${el.name}.webp 1x, /img/pages/home/progress/${el.name}_x2.webp 2x`}
                                type="image/webp"
                            />
                            <img
                                // loading={'lazy'}
                                alt={el.name}
                                key={el.className}
                                className={el.className}
                                srcSet={`/img/pages/home/progress/${el.name}.png 1x, /img/pages/home/progress/${el.name}_x2.png 2x`}
                                width={el.width} height={el.height}
                                src={`/img/pages/home/progress/${el.name}.png`}
                            />
                        </picture>
                    })}
                    {makeStaticParallax(stars, '/img/pages/home/how_to/', [2, 4])}

                    <div className='verse_section_title'>
                        <div className='title'>
                            <picture>
                                <source
                                    srcSet={`/img/pages/home/verse/title.webp 1x, /img/pages/home/verse/title_x2.webp 2x`}
                                    type="image/webp"
                                />
                                <img
                                    // loading={'lazy'}
                                    srcSet={`/img/pages/home/verse/title.png 1x, /img/pages/home/verse/title_x2.png 2x`}
                                    src={`/img/pages/home/verse/title.png`}
                                    alt="Buy alex the doge"
                                    width={478}
                                    height={183}
                                />
                            </picture>
                        </div>
                    </div>
                    <div className='map'>
                        <img
                            src={`/img/pages/home/verse/gif/GoldMining.gif`}
                            alt="Buy alex the doge"
                            width={1100}
                            height={933}
                            className={'mining'}
                            // loading={'lazy'}
                        />

                        <div className='gif_wrapper'>
                            <div id={'nfts'} className='inner inner_nfts'>
                                <ReactTooltip
                                    anchorId={'nfts'}
                                    place={'bottom'}
                                    content={<div><h4 style={{color: '000'}}>{verse.tooltips.nfts.title}</h4>
                                        <p>
                                            {verse.tooltips.nfts.body}
                                        </p>
                                    </div>}
                                    className='custom_tooltip'
                                />
                            </div>
                        </div>
                        <img
                            src={`/img/pages/home/verse/gif/nfts.gif`}
                            alt="Buy alex the doge"
                            width={1100}
                            height={933}
                            className={'nfts'}
                            // loading={'lazy'}
                        />

                        <div className='gif_wrapper'>
                            <div id={'miracle'} className='inner inner_miracle'>
                                <ReactTooltip
                                    anchorId={'miracle'}
                                    place={'bottom'}
                                    content={<div><h4 style={{color: '000'}}>{verse.tooltips.miracle_verse.title}</h4>
                                        <p>
                                            {verse.tooltips.miracle_verse.body}
                                        </p>
                                    </div>}
                                    className='custom_tooltip'
                                />
                            </div>
                        </div>
                        <img
                            src={`/img/pages/home/verse/gif/Miracle.gif`}
                            alt="Buy alex the doge"
                            width={1100}
                            height={933}
                            className={'miracle'}
                            // loading={'lazy'}
                        />

                        <div className='gif_wrapper'>
                            <div id={'social_fi'} className='inner inner_social_fi'>
                                <ReactTooltip
                                    anchorId={'social_fi'}
                                    place={'bottom'}
                                    content={<div><h4 style={{color: '000'}}>{verse.tooltips.social_fi.title}</h4>
                                        <p>
                                            {verse.tooltips.social_fi.body}
                                        </p>
                                    </div>}
                                    className='custom_tooltip'
                                />
                            </div>
                        </div>
                        <img
                            src={`/img/pages/home/verse/gif/Social-Fi.gif`}
                            alt="Buy alex the doge"
                            width={1100}
                            height={933}
                            className={'social_fi'}
                            // loading={'lazy'}
                        />

                        <div className='gif_wrapper'>
                            <div id={'alex_token'} className='inner inner_alex_token'>
                                <ReactTooltip
                                    anchorId={'alex_token'}
                                    place={'bottom'}
                                    content={<div><h4 style={{color: '000'}}>{verse.tooltips.alex_token.title}</h4>
                                        <p>
                                            {verse.tooltips.alex_token.body}
                                        </p>
                                    </div>}
                                    className='custom_tooltip'
                                />
                            </div>
                        </div>
                        <img
                            src={`/img/pages/home/verse/gif/AlexToken.gif`}
                            alt="Buy alex the doge"
                            width={1100}
                            height={933}
                            className={'alex_token'}
                            // loading={'lazy'}
                        />

                        <div className='gif_wrapper'>
                            <div id={'defi'} className='inner inner_defi'>
                                <ReactTooltip
                                    anchorId={'defi'}
                                    place={'bottom'}
                                    content={<div><h4 style={{color: '000'}}>{verse.tooltips.de_fi.title}</h4>
                                        <p>
                                            {verse.tooltips.de_fi.body}
                                        </p>
                                    </div>}
                                    className='custom_tooltip'
                                />
                            </div>
                        </div>
                        <img
                            src={`/img/pages/home/verse/gif/De-Fi.gif`}
                            alt="Buy alex the doge"
                            width={1100}
                            height={933}
                            className={'defi'}
                            // loading={'lazy'}
                        />
                        <img
                            src={`/img/pages/home/verse/gif/eth_spinning.gif`}
                            alt="Buy alex the doge"
                            width={49}
                            height={73}
                            className={'eth'}
                            // loading={'lazy'}
                        />
                        <picture>
                            <source
                                type="image/webp"
                                media="(max-width: 768px)"
                                srcSet={`/img/pages/home/verse/map_small.webp 1x, /img/pages/home/verse/map_small_x2.webp 2x`}
                            />
                            <source
                                type="image/webp"
                                media="(min-width: 769px)"
                                srcSet={`/img/pages/home/verse/map.webp 1x, /img/pages/home/verse/map_x2.webp 2x`}
                            />
                            <source
                                media="(max-width: 768px)"
                                srcSet={`/img/pages/home/verse/map_small.png 1x, /img/pages/home/verse/map_small_x2.png 2x`}
                            />
                            <source
                                media="(min-width: 769px)"
                                srcSet={`/img/pages/home/verse/map.png 1x, /img/pages/home/verse/map_x2.png 2x`}
                            />
                            <img
                                srcSet={`/img/pages/home/verse/map.png 1x, /img/pages/home/verse/map_x2.png 2x`}
                                src={`/img/pages/home/verse/map.png`}
                                alt="Buy alex the doge"
                                width={1100}
                                height={933}
                                className={'map_image'}
                                // loading={'lazy'}
                            />
                        </picture>
                    </div>
                    <div className='waves'>
                        <img
                            src={`/img/pages/home/verse/waves.svg`}
                            alt="Buy alex the doge"
                            width={130}
                            height={35}
                            className={'wave_1'}
                            // loading={'lazy'}
                        />
                        <img
                            src={`/img/pages/home/verse/waves.svg`}
                            alt="Buy alex the doge"
                            width={130}
                            height={35}
                            className={'wave_2'}
                            // loading={'lazy'}
                        />
                        <img
                            src={`/img/pages/home/verse/waves.svg`}
                            alt="Buy alex the doge"
                            width={93}
                            height={25}
                            className={'wave_3'}
                            // loading={'lazy'}
                        />
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Verse;