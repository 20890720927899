import React from 'react';
import {makeStaticParallax} from "../../../helpers/makeParalax";

const ImagesBlock = () => {

    const clouds_1 = [
        {
            name: 'cloud_left',
            className: 'cloud_left',
            width: '296',
            height: '135'
        }
    ]

    const clouds_2 = [
        {
            name: 'middle_cloud_top',
            className: 'middle_cloud_top',
            width: '213',
            height: '127'
        }, {
            name: 'middle_cloud_bottom',
            className: 'middle_cloud_bottom',
            width: '317',
            height: '197'
        }
    ]

    const clouds_3 = [
        {
            name: 'cloud_left',
            className: 'cloud_small',
            width: '181',
            height: '83'
        }, {
            name: 'cloud_left',
            className: 'cloud_middle',
            width: '193',
            height: '88'
        }, {
            name: 'cloud_left',
            className: 'cloud_big',
            width: '296',
            height: '135'
        }
    ]

    const stars = [
        {
            name: 'star',
            className: 'star star_1',
            width: '44',
            height: '59'
        }, {
            name: 'star',
            className: 'star star_2',
            width: '44',
            height: '59'
        }, {
            name: 'star',
            className: 'star star_3',
            width: '65',
            height: '87'
        }, {
            name: 'star',
            className: 'star star_4',
            width: '65',
            height: '87'
        }, {
            name: 'star',
            className: 'star star_5',
            width: '65',
            height: '87'
        }, {
            name: 'star',
            className: 'star star_6',
            width: '65',
            height: '87'
        }
    ]

    return (
        <section className='images_block_section'>
            <div className="container">
                <div className='images_block'>
                    <div className='block_1'>
                        <picture>
                            <source
                                type="image/webp"
                                srcSet={`/img/pages/home/how_to/coins_top.webp 1x, /img/pages/home/how_to/coins_top_x2.webp 2x`}
                            />
                            <img
                                // loading={'lazy'}
                                data-aos="fade-zoom-in"
                                data-aos-easing="ease-out-quad"
                                data-aos-duration="1000"
                                data-aos-delay='500'
                                srcSet="/img/pages/home/how_to/coins_top.png 1x, /img/pages/home/how_to/coins_top_x2.png 2x"
                                width='203' height='312' className='coins_top' alt='alex doge logo'
                                src='/img/pages/home/how_to/coins_top.png'
                            />
                        </picture>
                        <picture>
                            <source
                                type="image/webp"
                                srcSet={`/img/pages/home/how_to/coins_bottom.webp 1x, /img/pages/home/how_to/coins_bottom_x2.webp 2x`}
                            />
                            <img
                                // loading={'lazy'}
                                data-aos="fade-zoom-in"
                                data-aos-easing="ease-out-quad"
                                data-aos-duration="1000"
                                data-aos-delay='500'
                                srcSet="/img/pages/home/how_to/coins_bottom.png 1x, /img/pages/home/how_to/coins_bottom_x2.png 2x"
                                width='459' height='588' className='coins_bottom' alt='alex doge logo'
                                src='/img/pages/home/how_to/coins_bottom.png'
                            />
                        </picture>
                        {makeStaticParallax(clouds_1, '/img/pages/home/progress/', [2, 6])}
                    </div>
                    <div className='block_2'>
                        {makeStaticParallax(clouds_2, '/img/pages/home/how_to/', [2, 6])}
                        <picture>
                            <source
                                type="image/webp"
                                media="(max-width: 768px)"
                                srcSet={`/img/pages/home/how_to/box_small.webp 1x, /img/pages/home/how_to/box_small_x2.webp 2x`}
                            />
                            <source
                                type="image/webp"
                                media="(min-width: 769px)"
                                srcSet={`/img/pages/home/how_to/box.webp 1x, /img/pages/home/how_to/box_x2.webp 2x`}
                            />
                            <source
                                media="(max-width: 768px)"
                                srcSet={`/img/pages/home/how_to/box_small.png 1x, /img/pages/home/how_to/box_small_x2.png 2x`}
                            />
                            <source
                                media="(min-width: 769px)"
                                srcSet={`/img/pages/home/how_to/box.png 1x, /img/pages/home/how_to/box_x2.png 2x`}
                            />
                            <img
                                // loading={'lazy'}
                                data-aos="fade-down"
                                data-aos-easing="ease-out-quad"
                                data-aos-duration="500"
                                data-aos-delay='600'
                                srcSet="/img/pages/home/how_to/box.png 1x, /img/pages/home/how_to/box_x2.png 2x"
                                width='195' height='184' className='box' alt='alex doge logo'
                                src='/img/pages/home/progress/box.png'
                            />
                        </picture>
                    </div>
                    <div className='block_3'>
                        {makeStaticParallax(clouds_3, '/img/pages/home/progress/', [3, 6])}
                        <picture>
                            <source
                                type="image/webp"
                                media="(max-width: 768px)"
                                srcSet={`/img/pages/home/how_to/alex_ship_small.webp 1x, /img/pages/home/how_to/alex_ship_small_x2.webp 2x`}
                            />
                            <source
                                type="image/webp"
                                media="(min-width: 769px)"
                                srcSet={`/img/pages/home/how_to/alex_ship.webp 1x, /img/pages/home/how_to/alex_ship_x2.webp 2x`}
                            />
                            <source
                                media="(max-width: 768px)"
                                srcSet={`/img/pages/home/how_to/alex_ship_small.png 1x, /img/pages/home/how_to/alex_ship_small_x2.png 2x`}
                            />
                            <source
                                media="(min-width: 769px)"
                                srcSet={`/img/pages/home/how_to/alex_ship.png 1x, /img/pages/home/how_to/alex_ship_x2.png 2x`}
                            />
                            <img
                                // loading={'lazy'}
                                data-aos="fade-right"
                                data-aos-easing="ease-out-quad"
                                data-aos-duration="800"
                                data-aos-delay='200'
                                srcSet="/img/pages/home/how_to/alex_ship.png 1x, /img/pages/home/how_to/alex_ship_x2.png 2x"
                                width='433' height='240' className='alex_ship' alt='alex doge logo'
                                src='/img/pages/home/progress/alex_ship.png'
                            />
                        </picture>
                    </div>
                    {makeStaticParallax(stars, '/img/pages/home/how_to/', [4, 8])}
                </div>
            </div>
        </section>
    );
};

export default ImagesBlock;