import React from 'react';
import {makeStaticParallax} from "../../../helpers/makeParalax";
import {Tooltip as ReactTooltip} from "react-tooltip";

const RoadMap = ({t}) => {

    const road_map = t('common:road_map', {}, {returnObjects: true})

    const clouds = [
        {
            name: 'cloud_left',
            className: 'cloud cloud_left_1',
            width: '136',
            height: '61'
        }, {
            name: 'cloud_left',
            className: 'cloud cloud_left_2',
            width: '161',
            height: '73'
        }, {
            name: 'cloud_left',
            className: 'cloud cloud_left_3',
            width: '136',
            height: '61'
        }, {
            name: 'cloud_left',
            className: 'cloud cloud_left_4',
            width: '155',
            height: '70'
        }, {
            name: 'cloud_left',
            className: 'cloud cloud_left_5',
            width: '207',
            height: '93'
        }, {
            name: 'cloud_left',
            className: 'cloud cloud_left_6',
            width: '113',
            height: '51'
        }, {
            name: 'cloud_left',
            className: 'cloud cloud_left_7',
            width: '113',
            height: '51'
        },
    ]

    const phaseItems = [
        {
            className: 'phase_1',
            tooltipData: {
                text: <div><h4 style={{color: '000'}}>{road_map.stages.one.name}</h4>
                    <ul>{road_map.stages.one.data.map(item => {
                        return <li key={item}>{item}</li>
                    })}</ul>
                </div>,
                id: 'phase_1',
                dir: 'bottom'
            }
        }, {
            className: 'phase_2',
            tooltipData: {
                text: <div><h4 style={{color: '000'}}>{road_map.stages.two.name}</h4>
                    <ul>{road_map.stages.two.data.map(item => {
                        return <li key={item}>{item}</li>
                    })}</ul>
                </div>,
                id: 'phase_2',
                dir: 'bottom'
            }
        }, {
            className: 'phase_3',
            tooltipData: {
                text: <div><h4 style={{color: '000'}}>{road_map.stages.three.name}</h4>
                    <ul>{road_map.stages.three.data.map(item => {
                        return <li key={item}>{item}</li>
                    })}</ul>
                </div>,
                id: 'phase_3',
                dir: 'bottom'
            }
        }, {
            className: 'phase_4',
            tooltipData: {
                text: <div><h4 style={{color: '000'}}>{road_map.stages.four.name}</h4>
                    <ul>{road_map.stages.four.data.map(item => {
                        return <li key={item}>{item}</li>
                    })}</ul>
                </div>,
                id: 'phase_4',
                dir: 'bottom'
            }
        }, {
            className: 'phase_5',
            tooltipData: {
                text: <div><h4 style={{color: '000'}}>{road_map.stages.five.name}</h4>
                    <ul>{road_map.stages.five.data.map(item => {
                        return <li key={item}>{item}</li>
                    })}</ul>
                </div>,
                id: 'phase_5',
                dir: 'bottom'
            }
        }, {
            className: 'phase_6',
            tooltipData: {
                text: <div><h4 style={{color: '000'}}>{road_map.stages.six.name}</h4>
                    <ul>{road_map.stages.six.data.map(item => {
                        return <li key={item}>{item}</li>
                    })}</ul>
                </div>,
                id: 'phase_6',
                dir: 'bottom'
            }
        }, {
            className: 'phase_7',
            tooltipData: {
                text: <div><h4 style={{color: '000'}}>{road_map.stages.seven.name}</h4>
                    <ul>{road_map.stages.seven.data.map(item => {
                        return <li key={item}>{item}</li>
                    })}</ul>
                </div>,
                id: 'phase_7',
                dir: 'bottom'
            }
        }
    ]

    const PhaseItem = ({item}) => {
        return <div id={item.tooltipData.id} className={item.className}>
            <div className='wrapper_img'>
                <picture>
                    <source
                        srcSet={`/img/pages/home/roadmap/ask.webp`}
                        type="image/webp"
                    />
                    <img
                        // loading={'lazy'}
                        src={`/img/pages/home/roadmap/ask.png`}
                        alt="Buy alex the doge"
                        width={32}
                        height={31}
                    />
                </picture>
                <ReactTooltip
                    anchorId={item.tooltipData.id}
                    place={item.tooltipData.dir}
                    content={item.tooltipData.text}
                    className='custom_tooltip'
                />
            </div>
        </div>
    }

    return (
        <section className='roadmap_section' id='roadmap'>
            <div className="container">
                <div className="roadmap_section_wrapper">
                    {makeStaticParallax(clouds, '/img/pages/home/progress/', [4, 8])}
                    <div
                        data-aos="fade-up" data-aos-duration="800" data-aos-easing="ease-out-quad"
                        data-aos-delay={0}
                    >
                        <span className='roadmap_section_sub_title'>{road_map.title}</span>
                        <h4 className='roadmap_section_title'>{road_map.main_title}</h4>
                    </div>
                    <div className='roadmap_section_map'>
                        <picture>
                            <source
                                type="image/webp"
                                media="(max-width: 768px)"
                                srcSet={`/img/pages/home/roadmap/roadmap_small.webp 1x, /img/pages/home/roadmap/roadmap_small_x2.webp 2x`}
                            />
                            <source
                                type="image/webp"
                                media="(min-width: 769px)"
                                srcSet={`/img/pages/home/roadmap/roadmap.webp 1x, /img/pages/home/roadmap/roadmap_x2.webp 2x`}
                            />
                            <source
                                media="(max-width: 768px)"
                                srcSet={`/img/pages/home/roadmap/roadmap_small.png 1x, /img/pages/home/roadmap/roadmap_small_x2.png 2x`}
                            />
                            <source
                                media="(min-width: 769px)"
                                srcSet={`/img/pages/home/roadmap/roadmap.png 1x, /img/pages/home/roadmap/roadmap_x2.png 2x`}
                            />
                            <img
                                // loading={'lazy'}
                                src={`/img/pages/home/roadmap/roadmap.png`}
                                alt="Buy alex the doge"
                                width={1150}
                                height={659}
                                className={'map'}
                            />
                        </picture>
                        {phaseItems.map(item => {
                            return <PhaseItem
                                key={item.className}
                                item={item}
                            />
                        })}
                        <picture>
                            <source
                                type="image/webp"
                                srcSet={`/img/pages/home/roadmap/doge_run.webp 1x, /img/pages/home/roadmap/doge_run_x2.webp 2x`}
                            />
                            <img
                                // loading={'lazy'}
                                srcSet={`/img/pages/home/roadmap/doge_run.png 1x, /img/pages/home/roadmap/doge_run_x2.png 2x`}
                                src={`/img/pages/home/roadmap/doge_run.png`}
                                alt="Buy alex the doge"
                                width={82}
                                height={117}
                                className={'doge_run'}
                            />
                        </picture>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default RoadMap;